import { Col, Row } from '@advanza/grid'
import {
    BaseModalFooter,
    BaseModalHeader,
    BaseModalMainContent,
    Button,
    NewBaseModal,
} from '@advanza/ui'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { ComponentPropsWithoutRef, ReactNode } from 'react'

interface Props {
    header: ReactNode
    content: ReactNode
    cancelContent: ReactNode
    confirmContent: ReactNode
}

const ConfirmModal = NiceModal.create(
    ({ header, content, cancelContent, confirmContent }: Props) => {
        const modal = useModal()

        return (
            <NewBaseModal
                onClose={() => {
                    modal.resolve(false)
                    modal.hide()
                }}>
                <BaseModalHeader>
                    <h3>{header}</h3>
                </BaseModalHeader>
                <BaseModalMainContent>{content}</BaseModalMainContent>
                <BaseModalFooter>
                    <Row between="xs" middle="xs">
                        <Col x>
                            <Button
                                appearance="secondary"
                                onClick={() => {
                                    modal.resolve(false)
                                    modal.hide()
                                }}>
                                {cancelContent}
                            </Button>
                        </Col>
                        <Col x>
                            <Button
                                appearance="primary"
                                onClick={() => {
                                    modal.resolve(true)
                                    modal.hide()
                                }}>
                                {confirmContent}
                            </Button>
                        </Col>
                    </Row>
                </BaseModalFooter>
            </NewBaseModal>
        )
    }
)

type ConfirmModalHocProps = ComponentPropsWithoutRef<typeof ConfirmModal>

const showConfirmModal = (props: Props) =>
    NiceModal.show<
        boolean,
        ConfirmModalHocProps,
        Partial<ConfirmModalHocProps & { children?: ReactNode }>
    >(ConfirmModal, props)

export default showConfirmModal
