import { call } from '@advanza/api'
import { Button, Icon, LoadingDots } from '@advanza/ui'
import AutoRow from 'components/misc/AutoRow'
import { ReactNode, useState } from 'react'

interface Props {
    serviceId: number
    children?: ReactNode
}

const InvalidateServiceCachesButton = ({
    serviceId,
    children = 'Invalidate Service Caches',
}: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState<
        { status: string; error?: string } | object | undefined
    >(undefined)

    const createInvalidation = () => {
        setIsLoading(true)
        call(`office/services/invalidate-service-caches/${serviceId}`, { method: 'post' })
            .then(
                (response) => setResponse(response),
                (response) => setResponse(response)
            )
            .finally(() => setIsLoading(false))
    }

    return (
        <Button onClick={createInvalidation} appearance="secondary">
            <AutoRow>
                {children}
                {isLoading && <LoadingDots />}
                {response &&
                    ('status' in response && response.status === 'success' ? (
                        <Icon name="thumb_up" green />
                    ) : (
                        <Icon
                            name="thumb_down"
                            red
                            title={String('error' in response ? response.error : response)}
                        />
                    ))}
            </AutoRow>
        </Button>
    )
}

export default InvalidateServiceCachesButton
