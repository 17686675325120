import BaseData from 'interfaces/BaseData'

const COMMON_OPTIONS = [
    'icon',
    'cta_listing_long',
    'cta_listing_short',
    'cta_profile',
    'type',
    'cta_listing_type',
    'cta_profile_type',
] as const

const WIZARD_OPTIONS = [
    'name_form_start_sentence',
    'match2more',
    'contact_screen',
    'cta_contact',
] as const

const ALL_OPTIONS = [...COMMON_OPTIONS, 'request', ...WIZARD_OPTIONS] as const

export const CTA_TYPES = {
    call: COMMON_OPTIONS,
    quote: ALL_OPTIONS,
    contact: [...COMMON_OPTIONS, 'request', 'name_form_start_sentence', 'cta_contact'],
    contact_toggle: ALL_OPTIONS,
    contact_options: COMMON_OPTIONS,
    availability: ALL_OPTIONS,
    brochure: [...COMMON_OPTIONS, 'request', 'contact_screen', 'cta_contact'],
    website: COMMON_OPTIONS,
    profile: ['icon', 'cta_listing_long', 'cta_listing_short', 'type', 'cta_listing_type'],
} as const

export default interface Cta extends BaseData {
    readonly cta_id: number | string
    readonly service_id: number
    readonly variant: boolean
    readonly order_value: number
    readonly icon: string | null
    readonly cta_listing_long: string | null
    readonly cta_listing_short: string | null
    readonly cta_profile: string | null
    readonly type: keyof typeof CTA_TYPES
    readonly request: string | null
    readonly cta_listing_type: string
    readonly cta_profile_type: string
    readonly name_form_start_sentence: string | null
    readonly match2more: string | null
    readonly contact_screen: string | null
    readonly cta_contact: string | null
}
